/////////// Patrimoine Data ///////////
export const ForceImpactPatrimoineOptions = {
    size: {
        height: 200,
    },
    data: {
        columns: [
            ['Financier', 7, 4, 7, 4, 47],
            ['Marketing', 8, 9, 8, 8, 8],
            ['R&D', 30, 7, 8, 4, 2],
            ['RH', 10, 1, 41, 4],
            ['SI', 10, 1, 8, 7,],
        ],
        type: 'bar'
    },
    color: {
        pattern: [
            " rgb(116, 208, 241)",
            "rgb(242, 95, 10)",
            "rgb(68, 110, 155)",
            "rgb(255 156 112)",
            "rgb(151, 223, 198)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },
    bar: {
        width: {
            ratio: 0.6
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Faible", "Fort", "Moyen FORCE", "Non existant", "Trés Fort"],
            label: {
                text: 'FORCE',
                position: 'outer-center'


            }
        },
        y: {

            label: {
                text: 'TOTAL',
                position: 'outer-middle'

            }
        },
    }

}

export const typeImpactObjectOptions = {
    size: {
        height: 200,

    },
    data: {
        columns: [
            ['Application', 7, 4, 7, 4],
            ['Data', 8, 9, 8, 8],
            ['infrastructure', 30, 7, 8, 4],
            ['processus', 10, 1, 41, 4],
        ],
        type: 'bar',
        groups: [
            ['Application', 'Data', 'infrastructure', 'processus']
        ]
    },
    color: {
        pattern: [
            " rgb(116, 208, 241)",
            "rgb(242, 95, 10)",
            "rgb(68, 110, 155)",
            "rgb(255 156 112)",
            "rgb(151, 223, 198)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 120,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Création", "Indépendance", "Modification", "Suppression"],

            label: {
                text: 'IMPACTS',
                position: 'outer-middle'


            }
        },
        y: {

            label: {
                text: 'TOTAL',
                position: 'outer-center'

            }
        },
        rotated: true

    }

}

export const ApplicationsEligibilesClaudificationOptions = {
    size: {
        height: 200,
    },
    data: {
        columns: [
            ["Financier", 30],
            ["Marketing", 104],
            ["R&D", 144],
            ["RH", 150],
            ["SI", 12],

        ],
        type: "pie",
    },
    color: {
        pattern: [" rgb(116, 208, 241)",
            "rgb(242, 95, 10)",
            "rgb(68, 110, 155)",
            "rgb(255 156 112)",
            "rgb(151, 223, 198)",],
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 0,
    },
};

export const FluxApplicatifsOptions = {
    size: {
        height: 200,

    },
    data: {
        columns: [
            ["Financier", 30, 4, 7],
            ["Marketing", 4, 9, 5],
            ["R&D", 30, 7, 8],
            ["RH", 30, 7, 8],
            ["SI", 5, 10, 1],
        ],
        type: 'bar',
    },
    color: {
        pattern: [
            " rgb(116, 208, 241)",
            "rgb(242, 95, 10)",
            "rgb(68, 110, 155)",
            "rgb(255 156 112)",
            "rgb(151, 223, 198)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 80,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Autres", "Externe", "Interne"],
            label: {
                text: 'FLUX',
                position: 'outer-middle'


            }
        },
        y: {

            label: {
                text: 'TOTAL',
                position: 'outer-center'

            }
        },
        rotated: true

    }

};


export const TauxDeRefcardParStatusOptions = {
    size: {
        height: 150,
    },
    data: {
        columns: [
            ["Déployé", 330],
            ["Validé", 104],
        ],
        type: "pie",
    },
    pie: {
        label: {
            show: false,
        }
    },
    color: {
        pattern: [
            "rgb(116, 208, 241)",
            "rgb(242, 95, 10)",
        ],
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 0,
    },
};





////////////////////////////////


/////////// Securité data /////////////

export const SensibiliteSiParDomaineOptions = {
    size: {
        height: 250,

    },
    data: {
        columns: [
            ['Financier', 7, 4, 7, 4],
            ['Marketing', 8, 9, 8, 8],
            ['R&D', 30, 7, 8, 4],
            ['RH', 10, 1, 41, 4],
            ['SI', 10, 1, 41, 4],
        ],
        type: 'bar',
        groups: [
            ['Financier', 'Marketing', 'R&D', 'RH', 'SI']
        ]
    },
    color: {
        pattern: [
            "coral",
            "rgb(255, 203, 96)",
            "rgb(190, 245, 116)",
            "rgb(68, 110, 155)",
            "#9966ff",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 90,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Niveau 1", "Niveau 2", "Niveau 3", "Non Audité"],
            label: {
                text: 'NIVEAU',
                position: 'outer-middle'

            }
        },
        y: {

            label: {
                text: 'TOTAL',
                position: 'outer-center'

            }
        },
        rotated: true

    }

}



export const TauxSensibilitesSiOptions = {
    size: {
        height: 250,
    },
    data: {
        x: 'x',
        columns:
            [
                ['x', "Niveau 1", "Niveau 2", "Niveau 3", "Non Audité"],
                ['value', 4, 7, 9, 6]
            ],
        color: function (inColor, data) {
            var colors = [
                "coral",
                "rgb(255, 203, 96)",
                "rgb(190, 245, 116)",
                "rgb(68, 110, 155)"
            ];
            if (data.index !== undefined) {
                return colors[data.index];
            }

            return inColor;
        },

        type: 'bar'
    },
    legend: {
        show: false,
    },

    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Niveau 1", "Niveau 2", "Niveau 3", "Non Audité"],
            label: {
                text: 'SENSIBILITE',
                position: 'outer-center'

            }
        },
        y: {

            label: {
                text: 'TAUX',
                position: 'outer-middle'

            },
            tick: {
                format: function (d) { return d + '%'; }
            }
        },

    },

    tooltip: {
        grouped: false
    },













}


export const SensibiliteSIExterneOptions = {
    size: {
        height: 250,

    },
    data: {
        columns: [
            ['Financier', 7, 4, 7, 4],
            ['Marketing', 8, 9, 8, 8],
            ['R&D', 30, 7, 8, 4],
            ['RH', 10, 1, 41, 4],
            ['SI', 10, 1, 41, 4],
        ],
        type: 'bar',
    },
    color: {
        pattern: [
            "#003049",
            "#d62828",
            "#f77f00",
            "#7209b7",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Niveau 1", "Niveau 2", "Niveau 3", "Non Audité"],
            label: {
                text: 'SENSUBILITE',
                position: 'outer-center'

            }
        },
        y: {

            label: {
                text: 'TOTAL',
                position: 'outer-middle'


            }
        },

    }

}


export const TauxSensibiliteSiExterneOptions = {
    size: {
        height: 250,

    },
    data: {
        columns: [
            ['Financier', 7, 4, 7, 4],
            ['Marketing', 8, 9, 8, 8],
            ['R&D', 30, 7, 8, 4],
            ['RH', 10, 1, 41, 4],
            ['SI', 10, 1, 41, 4],
        ],
        type: 'bar',
        groups: [
            ['Financier', 'Marketing', 'R&D', 'RH', 'SI']
        ]
    },
    color: {
        pattern: [
            "#003049",
            "#d62828",
            "#f77f00",
            "#7209b7",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 60,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Niveau 1", "Niveau 2", "Niveau 3", "Non Audité"],
            label: {
                text: 'SENSIBILITE',
                position: 'outer-center'

            }
        },
        y: {

            label: {
                text: 'TAUX',
                position: 'outer-middle'

            },
            tick: {
                format: function (d) { return d + '%'; }
            }
        },

    }

}



///////////////////////////////////////////


export const NatureTraitementDcpOptions = {
    size: {
        height: 250,
    },
    data: {
        columns: [
            ['Financier', 7, 4, 7, 4, 47],
            ['Marketing', 8, 9, 8, 8, 8],
            ['R&D', 30, 7, 8, 4, 2],
            ['RH', 10, 1, 41, 4],
            ['SI', 10, 1, 8, 7,],
        ],
        type: 'bar'
    },
    color: {
        pattern: [
            "rgba(255,99,132,1)",
            "rgba(255, 159, 64,1)",
            "rgba(153, 102, 255,1)",
            "rgba(54, 162, 235,1)",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Faible", "Fort", "Moyen FORCE", "Non existant", "Trés Fort"],
            label: {
                text: 'TRAITEMENT',
                position: 'outer-center'


            }
        },
        y: {

            label: {
                text: 'TOTAL',
                position: 'outer-middle'

            }
        },
    }

}




export const TraitementDCPTypeHbergementOptions = {
    size: {
        height: 250,

    },
    data: {
        columns: [
            ['Anonymisation', 7, 4, 7],
            ['Autres', 8, 9, 8],
            ['Purge', 30, 7, 8],
            ["Restriction d'accés", 10, 1, 41],
        ],
        type: 'bar',
        groups: [
            ['Anonymisation', 'Autres', 'Purge', "Restriction d'accés"]
        ]
    },
    color: {
        pattern: [
            "rgba(255,99,132,1)",
            "rgba(255, 159, 64,1)",
            "rgba(153, 102, 255,1)",
            "rgba(54, 162, 235,1)",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 60,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Autres", "Cloud", "On Premise"],

            label: {
                text: 'HEBERGEMENT',
                position: 'outer-center'


            }
        },
        y: {

            label: {
                text: 'TOTAL',
                position: 'outer-middle'

            },
            tick: {
                format: function (d) { return d + '%'; }
            }
        },

    }

}



////////////////////Projet //////////////

export const NombreimpactProjetPatrimoineOptions = {
    size: {
        height: 250,

    },
    data: {
        x: 'x',
        columns: [
            ['x', 2021, 2022, 2023, 2024, 2025],
            ['SI', 30, 34, 100, 400, 150,],
            ['RH', 50, 20, 10, 40, 15,],
            ['Marketing', 230, 343, 107, 450, 124,],
            ['R&D', 57, 340, 70, 340, 145,],
            ['Financier', 50, 250, 600, 340, 650,],
        ],

    },
    color: {
        pattern: ['#003049', '#d62828', '#f77f00', '#560bad', '#06d6a0']
    },
    padding: {
        top: 0,
        right: 20,
        bottom: 30,
        left: 40,
    }
}




export const NombreProjetParObjectifOptions = {
    size: {
        height: 250,
    },
    data: {
        columns: [
            ['Vision 1', 7],
            ['Vision 2', 8],
            ['Vision 3', 4],
            ['Vision 4', 1],
            ['Vision 5', 7],
        ],
        type: 'bar'
    },
    color: {
        pattern: [
            "#003049",
            "#d62828",
            "#f77f00",
            "#7209b7",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },
    axis: {
        x: {
            // show: false,
            label: {
                text: 'Projets',
                position: 'outer-center'

            },
        },

    },
    bar: {
        space: 0.25,

    }
}


export const NombreProjetParVisionOptions = {
    size: {
        height: 250,
    },
    data: {
        columns: [
            ['Vision 1', 7],
            ['Vision 2', 8],
            ['Vision 3', 2],
            ['Vision 4', 4],
            ['Vision 5', 6],
        ],
        type: 'bar'
    },
    color: {
        pattern: [
            "#003049",
            "#d62828",
            "#f77f00",
            "#7209b7",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },
    axis: {
        x: {
            // show: false,
            label: {
                text: 'Projets',
                position: 'outer-center'

            },
        },

    },
    bar: {
        space: 0.25,

    }

}



export const TauxRespectPrincipeProjetOptions = {
    size: {
        height: 200,

    },
    data: {
        columns: [
            ['FDR1', 7, 4, 7, 4],
            ['FDR2', 8, 9, 8, 8],
            ['FDR3', 30, 7, 8, 4],
            ['FDR4', 10, 1, 41, 4],
            ['FDR5', 10, 1, 41, 4],
        ],
        type: 'bar',
        groups: [
            ['FDR1', 'FDR2', 'FDR3', 'FDR4', 'FDR5']
        ]
    },
    color: {
        pattern: [
            "#003049",
            "#d62828",
            "#f77f00",
            "#7209b7",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 60,
    },
    bar: {
        width: {
            ratio: 0.7 // this makes bar width 50% of length between ticks
        }
    },
    axis: {
        x: {

            type: 'category',
            categories: ["Projet 1", "Projet 2", "Projet 3", "Projet 4"],
            label: {
                text: 'Projets',
                position: 'outer-center'

            }
        },
        y: {

            label: {
                text: 'TAUX',
                position: 'outer-middle'

            },
            tick: {
                format: function (d) { return d + '%'; }
            }
        },

    }

}

////////////////////////////////////////////
export const NombreFDRDomaineOptions = {
    size: {
        height: 250,

    },
    data: {
        x: 'x',
        columns: [
            ['x', 2021, 2022, 2023, 2024, 2025],
            ['SI', 30, 34, 100, 400, 150,],
            ['RH', 50, 20, 10, 40, 15,],
            ['Marketing', 230, 343, 107, 450, 124,],
            ['R&D', 57, 340, 70, 340, 145,],
            ['Financier', 50, 250, 600, 340, 650,],
        ],

    },
    axis: {
    },
    color: {
        pattern: ['#003049', '#d62828', '#f77f00', '#560bad', '#06d6a0']
    },
    padding: {
        top: 0,
        right: 20,
        bottom: 30,
        left: 40,
    }
}


export const NombreFDRParObjectifOptions = {
    size: {
        height: 250,
    },
    data: {
        columns: [
            ['Objectif 1', 9],
            ['Objectif 2', 8],
            ['Objectif 3', 2],
            ['Objectif 4', 4],
            ['Objectif 5', 7],
        ],
        type: 'bar'
    },
    color: {
        pattern: [
            "rgba(255,99,132,1)",
            "rgba(255, 159, 64,1)",
            "rgba(153, 102, 255,1)",
            "rgba(54, 162, 235,1)",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },
    axis: {
        x: {
            // show: false,
            label: {
                text: 'Feuille de route',
                position: 'outer-center'

            },
        },

    },
    bar: {
        space: 0.25,

    }

}

export const NombreFDRParVisionOptions = {
    size: {
        height: 250,
    },
    data: {
        columns: [
            ['Vision 1', 47],
            ['Vision 2', 8],
            ['Vision 3', 2],
            ['Vision 4', 4],
            ['Vision 5', 17],
        ],
        type: 'bar'
    },
    color: {
        pattern: [
            "rgba(255,99,132,1)",
            "rgba(255, 159, 64,1)",
            "rgba(153, 102, 255,1)",
            "rgba(54, 162, 235,1)",
            "rgba(54, 65, 235,1)",
        ]
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 50,
    },

    axis: {
        x: {
            // show: false,
            label: {
                text: 'Feuille de route',
                position: 'outer-center'

            },
        },

    },
    bar: {
        space: 0.25,

    }

}
