<template>
  <div class="list-btn-action">
    <b-dropdown
      :id="'dropdownMenuLink' + id"
      text=""
      class="ml-auto my-auto custom-class"
    >
      <template #button-content class="p-0 custom">
        <i class="mdi mdi-menu text-black bg-white rounded p-1" />
      </template>
      <b-dropdown-item @click="viewInFullScreen">Voir en plein écran</b-dropdown-item>
      <b-dropdown-item @click="exportChartToPrint">Imprimer chart</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="exportChartToPNG">Télécharger l'image PNG</b-dropdown-item>
      <b-dropdown-item @click="exportChartToJPG"
        >Télécharger l'image JPEG</b-dropdown-item
      >
      <b-dropdown-item @click="exportChartToPDF"
        >Télécharger le document pdf</b-dropdown-item
      >
      <b-dropdown-item @click="exportChartToSVG">Télécharger l'image SVG</b-dropdown-item>
      <b-dropdown-divider v-if="options"></b-dropdown-divider>
      <b-dropdown-item v-if="options" @click="exportChartToCSV"
        >Télécharger CSV</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import domtoimage from "dom-to-image";
export default {
  props: {
    id: String,
    options: {
      type: Object,
    },
  },

  methods: {
    exportChartToSVG: function () {
      //get svg element.
      var svgData = document.getElementById(this.id).children[0].children[0].outerHTML;
      var svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
      var svgUrl = URL.createObjectURL(svgBlob);
      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = "newesttree.svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    exportChartToCSV: function () {
      const link = document.createElement("a");
      link.setAttribute("download", `${this.id}.csv`);
      link.setAttribute(
        "href",
        encodeURI(`data:text/csv;charset=utf-8,${this.options.data.columns.join("\n")}`)
      );
      link.click();
    },
    exportChartToPDF: function () {
      var element = document.getElementById(this.id);
      var opt = {
        margin: 1,
        filename: `${this.id}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      html2pdf().set(opt).from(element).save();
    },
    exportChartToPrint: function () {
      var elem = document.getElementById(this.id);
      var mywindow = window.open("", "PRINT", "height=500,width=1024");

      mywindow.document.write("<html><head><title>" + document.title + "</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(elem.innerHTML);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
      return true;
    },
    viewInFullScreen: function () {
      var elem = document.getElementById(this.id);

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    exportChartToPNG: function () {
      var element = document.getElementById(this.id);
      domtoimage.toPng(element, { quality: 1 }).then((dataUrl) => {
        var link = document.createElement("a");
        link.download = `${this.id}.png`;
        link.href = dataUrl;
        link.click();
      });
    },

    exportChartToJPG: function () {
      var element = document.getElementById(this.id);
      domtoimage.toJpeg(element, { quality: 1 }).then((dataUrl) => {
        var link = document.createElement("a");
        link.download = `${this.id}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
    },
  },
};
</script>
<style>
.list-btn-action .dropdown-toggle::after {
  content: none;
}
.list-btn-action .dropdown-toggle {
  border: 0;
  padding: 0;
}
.list-btn-action .dropdown-item {
  padding: 0 5px;
  font-size: smaller;
}
</style>
