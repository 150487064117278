<template>
  <div class="p-1">
    <div class="rounded border-0 bg-white bg-white">
      <div class="p-2 d-flex">
        <p class="p-0 mr-auto my-auto text-gray">{{ title }}</p>
        <ListButtonActions :options="options" :id="id" />
      </div>

      <bar-c3 :options="options" :id="id" class="col-12 px-3 pb-3 bg-white" />
    </div>
  </div>
</template>
<script>
import barC3 from "@/components/charts/c3-chart/bar-c3.vue";
import ListButtonActions from "./ListButtonActions.vue";
export default {
  components: { barC3, ListButtonActions },
  props: {
    options: Object,
    title: String,
  },
  data() {
    return {
      id: "barchart" + parseInt(Math.random() * 100),
    };
  },
  methods: {},
};
</script>

<style>
.c3-axis-x-label,
.c3-axis-y-label {
  font-weight: bold;
}
*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: rgb(255, 255, 255);
  padding: 50px;
}
</style>
